import React, { useCallback, useEffect, useRef, useState } from 'react';
import router, { useRouter } from 'next/router';
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Slide,
  Snackbar,
  Stack,
  SvgIcon,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AphAlert } from '@aph/components/common/aph-alert/aph-alert.component';
import CloseIcon from '@aph/icons/close.svg';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import { formatPersonalNumber } from '@aph/utilities/format-personal-number';
import { PriceFormatter } from '~/components/common';
import { useCartActions, useCartStore } from '~/model/cart/cart.store';
import { CostType } from '~/services/generated/PurchaseClient';
import theme from '~/styles/theme';
import { MiniCartLineItemContainer } from './mini-cart-line-item.container';
import { MiniCartSummary } from './mini-cart-summary.component';

export const MiniCart = () => {
  const { cart, loading, error, errorMessage, isMiniCartOpen, articleCount } = useCartStore();
  const { setIsMiniCartOpen, deleteCart, fetch } = useCartActions();

  const hvArticles = cart?.lineItems?.filter((i) => i.productGroup === 'Hv');
  const rxArticles = cart?.lineItems?.filter((i) => i.productGroup === 'Rx');

  const currentUrl = useRouter();

  const isCartEmpty = articleCount() === 0;

  const containerRef = useRef(null);

  const [emptyCartOpen, setEmptyCartOpen] = useState(false);

  const prescriptionDetails = cart?.prescriptionDetails;

  const [open, setOpen] = useState<boolean>(false);

  const intl = useIntl();

  const navigateToCheckout = () => {
    if (currentUrl.asPath.includes('rekommendationer')) {
      router.push('/kassa');
    } else {
      router.push('/rekommendationer').then(() => setIsMiniCartOpen(false));
    }
  };

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = useCallback(() => {
    setOpen(false);
    fetch();
  }, [fetch]);

  return (
    <Drawer
      anchor="right"
      open={isMiniCartOpen}
      onClose={() => setIsMiniCartOpen(false)}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            md: '500px',
          },
          borderTopLeftRadius: { md: 24 },
          borderBottomLeftRadius: { md: 24 },
        },
      }}
    >
      <Stack justifyContent="space-between" height="100%" width="100%">
        {/* minicart title bar */}
        <Stack
          direction="row"
          alignItems="flex-end"
          bgcolor="color/background/elevated"
          px={2}
          py={3}
          height={100}
        >
          <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
            <Typography typography="headingExtraLarge" color="text-brand">
              <FormattedMessage id="MINICART.TITLE" />
            </Typography>
            <IconButton aria-label="Lämna varukorg" onClick={() => setIsMiniCartOpen(false)}>
              <SvgIcon fontSize="medium" sx={{ color: 'color/icon/default' }}>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        {/* minicart body */}
        <Stack
          height="100%"
          overflow="auto"
          justifyContent={isCartEmpty ? 'center' : 'flex-start'}
          ref={containerRef}
        >
          {!isCartEmpty && !loading && cart && (
            <>
              {hvArticles?.map((article, index) => (
                <MiniCartLineItemContainer
                  lineItem={article}
                  cartId={cart.id ?? ''}
                  key={article.id}
                  index={index}
                  cartLoading={loading}
                />
              ))}
              <div>
                {!!rxArticles?.length &&
                  prescriptionDetails?.map((bag) => (
                    <Stack key={bag.id}>
                      <Box p="16px" bgcolor={theme.palette['color/background/default']}>
                        <Typography typography="bodyBig" className="font-bold">
                          <FormattedMessage
                            id={
                              bag.isForAnimal
                                ? 'MINICART.PRESCRIPTION_ANIMAL'
                                : 'MINICART.PRESCRIPTION_FOR'
                            }
                            values={{ name: bag.ownerFullName }}
                          />
                        </Typography>
                        {!bag.isForAnimal && (
                          <Typography color="text-subtle">
                            {formatPersonalNumber(bag.personalNumber)}
                          </Typography>
                        )}
                      </Box>
                      {rxArticles
                        .filter((i) => i.rxItemProperties?.prescriptionBagId === bag.id)
                        .map((article, index) => (
                          <MiniCartLineItemContainer
                            lineItem={article}
                            index={index}
                            cartId={cart.id ?? ''}
                            key={article.id}
                            cartLoading={loading}
                          />
                        ))}
                      <span className="sr-only relative">
                        {intl.formatMessage({ id: 'SUMMARY.CART.RX' })}
                      </span>
                      <Stack p="16px" gap={0.5}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>
                            <FormattedMessage id="MINICART.PRESCRIPTION_PRICE_TOTAL" />
                          </Typography>
                          <PriceFormatter
                            price={
                              bag.priceDetails?.find((item) => item.type === CostType.GrossPrice)
                                ?.value || 0
                            }
                            size="small"
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>
                            <FormattedMessage id="MINICART.PRESCRIPTION_DISCOUNT" />
                          </Typography>
                          <PriceFormatter
                            price={
                              bag.priceDetails?.find((item) => item.type === CostType.TotalBenefit)
                                ?.value || 0
                            }
                            size="small"
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>
                            <FormattedMessage id="MINICART.PRESCRIPTION_PRICE" />
                          </Typography>
                          <PriceFormatter
                            price={
                              bag.priceDetails?.find((item) => item.type === CostType.TotalPrice)
                                ?.value || 0
                            }
                            size="medium"
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
              </div>
              {!emptyCartOpen && (
                <Stack padding="16px" alignItems="center">
                  <Button
                    data-pw="mini-cart-empty-button"
                    onClick={() => setEmptyCartOpen(true)}
                    variant="secondary"
                  >
                    <FormattedMessage id="MINICART.EMPTY_CART" />
                  </Button>
                </Stack>
              )}
            </>
          )}
          {isCartEmpty && !loading && (
            <Typography color="text-brand" className="text-center font-bold">
              <FormattedMessage id="MINICART.EMPTY" />
            </Typography>
          )}
          {loading && (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <CircularProgress color="primary" />
            </Stack>
          )}
        </Stack>
        <Slide
          direction="up"
          in={emptyCartOpen}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center"
            bgcolor="#E5F1E4"
            p="24px"
          >
            <Typography typography="bodyBig">
              <FormattedMessage id="MINICART.ARE_YOU_SURE" />
            </Typography>
            <Stack
              direction="row"
              mt="16px"
              justifyContent="space-evenly"
              whiteSpace="nowrap"
              width="100%"
            >
              <Button
                data-pw="mini-cart-empty-no-button"
                onClick={() => setEmptyCartOpen(false)}
                variant="secondary"
              >
                <FormattedMessage id="MINICART.NO" />
              </Button>
              <Button
                data-pw="mini-cart-empty-yes-button"
                onClick={() => {
                  deleteCart();
                  setEmptyCartOpen(false);
                }}
              >
                <FormattedMessage id="MINICART.YES" />
              </Button>
            </Stack>
          </Stack>
        </Slide>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box>
              <AphAlert
                data-pw="mini-cart-alert"
                severity="info"
                variant="filled"
                title={errorMessage}
              />
            </Box>
          </Snackbar>
        )}
        {/* minicart Bottom bar */}
        <Stack padding="16px" bgcolor={theme.palette['color/background/default']}>
          {isCartEmpty ? (
            <Button
              data-pw="mini-cart-start-shopping-button"
              onClick={() => setIsMiniCartOpen(false)}
            >
              <FormattedMessage id="MINICART.START_SHOPPING" />
            </Button>
          ) : (
            <>
              <span className="sr-only relative">
                {intl.formatMessage({ id: 'SUMMARY.CART.TOTAL' })}
              </span>
              <MiniCartSummary />
              <Button
                data-pw="mini-cart-go-to-checkout-button"
                className="w-full"
                onClick={navigateToCheckout}
              >
                <FormattedMessage id="MINICART.GO_TO_CHECKOUT" />
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};
