import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Box, InputAdornment, InputBase, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendQuickSearchEvent } from '@aph/components/gtm/events/search-gtm';
import { useDebounce } from '@aph/hooks/use-debounce';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { Typography } from '@aph/ui/components/typography/typography';
import { SearchSource, SearchType } from '~/articles/generated/ArticlesClient';
import { SearchApiClient } from '~/search/api/SearchApiClient';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { CategorySearchResults } from './category-search-results';
import { PharmacySearchResults } from './pharmacy-search-results';
import { ProducSearchResults } from './product-search-results';
import { SuggestionsSearchResults } from './suggestions-search-results';

function useQuickSearch(searchPhrase: string) {
  return useQuery({
    queryKey: ['quick-search', { searchPhrase }],
    queryFn: async () => {
      sendQuickSearchEvent(searchPhrase);
      const searchApiClient = new SearchApiClient();
      return searchApiClient.getSearchResult({
        searchPhrase,
        take: 12,
        source: SearchSource.Articles,
        type: SearchType.QuickSearch,
      });
    },
    enabled: searchPhrase.length > 2,
    staleTime: Infinity,
    gcTime: 2 * 60 * 1000, // 2 minutes
  });
}

interface SearchResultCardProps {
  heading: string;
  children?: React.ReactNode;
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({ heading, children }) => {
  return (
    <Box padding={2}>
      <Typography typography="headingSmall" asChild>
        <h3>{heading}</h3>
      </Typography>
      <Box
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette['color/border/subtle']}
        marginX={-2}
        marginBottom={1}
      />

      {children}
    </Box>
  );
};

interface SearchResultProps {
  searchPhrase: string;
}

const SearchResult: React.FC<SearchResultProps> = ({ searchPhrase }) => {
  const getSearchResultQuery = useQuickSearch(searchPhrase);
  const intl = useIntl();
  const theme = useTheme();
  const isRowLayout = useMediaQuery(theme.breakpoints.up('lg'));

  const categorySearchResult = (
    <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.CATEGORIES' })}>
      <CategorySearchResults
        data={getSearchResultQuery.data?.categories}
        isLoading={getSearchResultQuery.isFetching}
      />
    </SearchResultCard>
  );

  const productSearchResults = (
    <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.PRODUCTS' })}>
      <ProducSearchResults
        data={getSearchResultQuery?.data?.articles}
        isLoading={getSearchResultQuery.isFetching}
        query={searchPhrase}
      />
    </SearchResultCard>
  );

  const suggestionSearchResults = (
    <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.SUGGESTIONS' })}>
      <SuggestionsSearchResults
        data={getSearchResultQuery?.data?.suggestions}
        isLoading={getSearchResultQuery.isFetching}
      />
    </SearchResultCard>
  );

  const pharmacySearchResults = getSearchResultQuery.isSuccess ? (
    <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.APOTEK' })}>
      <PharmacySearchResults
        data={getSearchResultQuery.data?.stores?.results}
        isLoading={getSearchResultQuery.isFetching}
      />
      <div className="flex">
        <Button data-pw="quick-search-button" variant="secondary" size="small" asChild>
          <FeatureToggledNextLink href={`/hitta-apotek-hjartat/?q=${searchPhrase}`}>
            <FormattedMessage id="COMMON.HEADER.SEARCH.FINDAPOTEK" />
          </FeatureToggledNextLink>
        </Button>
      </div>
    </SearchResultCard>
  ) : null;

  if (getSearchResultQuery.isFetching || getSearchResultQuery.isSuccess) {
    return (
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        columnGap={2}
        marginBottom={{ md: 6 }}
        minHeight={{ md: '80vh' }}
      >
        {isRowLayout ? (
          <>
            <Stack direction="column" width="35%" flexShrink={0}>
              {suggestionSearchResults}
              {categorySearchResult}
              {pharmacySearchResults}
            </Stack>
            <Stack direction="column" width="50%" flexShrink={0}>
              {productSearchResults}
            </Stack>
          </>
        ) : (
          <>
            {suggestionSearchResults}
            {productSearchResults}
            {categorySearchResult}
            {pharmacySearchResults}
          </>
        )}
      </Stack>
    );
  }

  return null;
};

interface QuickSearchProps {
  placeholder: string;
  onClose: () => void;
}

export const QuickSearch: React.FC<QuickSearchProps> = ({ placeholder, onClose }) => {
  const [value, setValue] = useState('');
  const searchPhrase = useDebounce(value, 500);
  const router = useRouter();

  return (
    <Stack direction="column" padding={1.5} paddingTop={0} overflow="auto">
      <Stack
        direction="row"
        gap={4}
        position="sticky"
        top={0}
        bgcolor={(theme) => theme.palette['color/background/elevated']}
        zIndex={1}
        paddingTop={1.5}
      >
        <InputBase
          value={value}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              router.push({ pathname: '/soksida', query: { query: value } });
            }
          }}
          autoFocus
          placeholder={placeholder}
          fullWidth
          sx={{
            display: 'flex',
            borderRadius: '100px',
            backgroundColor: (theme) => theme.palette['color/background/default'],
            paddingX: 2.5,
            paddingY: 1.5,

            // The theme has other props on InputBase that we need to override here
            '& .MuiInputBase-input': {
              typography: (theme) => theme.typography.body1,
              color: (theme) => theme.palette['color/text/subtle'],
            },
          }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Icon name="MagnifyingGlass" fill="fill-action" size="large" />
            </InputAdornment>
          }
          endAdornment={
            value.length > 0 ? (
              <InputAdornment position="end">
                <button
                  type="button"
                  aria-label="Rensa"
                  className="focus-within:outline"
                  onKeyDown={(event) => event.stopPropagation()}
                  onClick={() => {
                    setValue('');
                  }}
                >
                  <FormattedMessage id="COMMON.HEADER.SEARCH.CLEAR" />
                </button>
              </InputAdornment>
            ) : null
          }
        />
        <button aria-label="Stäng" type="button" onClick={onClose}>
          <div className="sr-only">
            <FormattedMessage id="COMMON.HEADER.SEARCH.CLOSE" />
          </div>
          <Icon name="Close" size="large" />
        </button>
      </Stack>
      <div className="overflow-auto">
        <SearchResult searchPhrase={searchPhrase} />
      </div>
    </Stack>
  );
};
