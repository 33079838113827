import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { IArticleReferenceResultCollection } from '~/articles/generated/ArticlesClient';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { ProductCard } from '../../product-card/product-card';
import type { QueryResult } from './types';

interface ProducSearchResultsProps extends QueryResult<IArticleReferenceResultCollection> {
  query: string | undefined;
}
export const ProducSearchResults: React.FC<ProducSearchResultsProps> = ({
  isLoading,
  data,
  query,
}) => {
  let products = null;
  let showmore = null;

  if (isLoading) {
    products = (
      <>
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
      </>
    );
  } else {
    products = data?.results?.map((article, index) => (
      <li
        key={article.articleCode}
        className={cn('pb-2', {
          'border-subtle border-b-2': index < (data?.results?.length ?? 0) - 1,
          'pt-2': index >= 1,
        })}
      >
        <ProductCard
          variant="searchresult"
          article={article}
          index={index + 1}
          listName="sökresultat"
        />
      </li>
    ));

    if ((data?.results?.length ?? 0) < 1) {
      products = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }

    if ((data?.resultsCount ?? 0) > (data?.results?.length ?? 0)) {
      showmore = (
        <div className="mt-3 flex">
          <Button data-pw="product-search-results-button" variant="secondary" size="small" asChild>
            <FeatureToggledNextLink href={`/soksida/?query=${query}`}>
              <FormattedMessage
                id="COMMON.HEADER.SEARCH.HITS"
                values={{ total: data?.resultsCount }}
              />
              <Icon name="ArrowRight" size="small" />
            </FeatureToggledNextLink>
          </Button>
        </div>
      );
    }
  }

  return (
    <>
      <ol className="flex flex-col">{products}</ol>
      {showmore}
    </>
  );
};
