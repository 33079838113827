import React from 'react';
import { Content, Header, Item, Root, Trigger } from '@radix-ui/react-accordion';
import type {
  AccordionContentProps,
  AccordionItemProps,
  AccordionMultipleProps,
  AccordionTriggerProps,
} from '@radix-ui/react-accordion';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import { Icon } from '../icon/icon';
import { Typography } from '../typography/typography';

const variations = cva('rounded-md', {
  variants: {
    variant: {
      default: 'bg-elevated child:border-subtle hover:shadow-md',
      outlined: 'border-default child:border-default border hover:shadow-md',
      inverted:
        'child:text-inverse child:fill-inverse border-visual-darkest hover:border-visual child:border-inverse border',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type AccordionItem = {
  title: string;
  value: AccordionItemProps['value'];
  children: React.ReactNode;
};

type VariantProps = CustomVariantProps<typeof variations>;

export interface AccordionProps
  extends VariantProps,
  Pick<AccordionMultipleProps, 'value' | 'onValueChange'> {
  items: AccordionItem[];
}
/**
 *
 * @example
 * ```tsx
 * <Accordion
 *  items={accordionItems}
 *  variant="outlined"
 *  defaultValue=["item-1]"
 * />
 * ```
 *
 */
export const Accordion: React.FC<AccordionProps> = ({ items, variant, ...props }) => (
  <AccordionRoot {...props}>
    {items.map((item) => (
      <AccordionItem value={item.value} variant={variant} key={item.value}>
        <AccordionTrigger variant={variant}>{item.title}</AccordionTrigger>
        <AccordionContent>{item.children}</AccordionContent>
      </AccordionItem>
    ))}
  </AccordionRoot>
);

const AccordionRoot = ({ children, ...rest }: Omit<AccordionMultipleProps, 'type'>) => (
  <Root type="multiple" className="flex flex-col gap-1" {...rest}>
    {children}
  </Root>
);

const AccordionItem = ({ children, variant, value }: AccordionItemProps & VariantProps) => (
  <Item value={value} className={cn('group', variations({ variant }))}>
    {children}
  </Item>
);

const AccordionTrigger = ({
  children,
  variant,
  ...props
}: AccordionTriggerProps & VariantProps) => {
  const isInverse = variant === 'inverted';
  return (
    <Header>
      <Trigger
        className={cn('flex w-full items-center gap-1 p-2 focus-visible:rounded-lg', {
          'outline-inverse': isInverse,
          'outline-action': !isInverse,
        })}
        {...props}
      >
        <Typography className="flex grow font-bold">{children}</Typography>
        <Icon name="ArrowheadDown" size="medium" className="group-data-[state=open]:rotate-180" />
      </Trigger>
    </Header>
  );
};

const AccordionContent = ({ children }: AccordionContentProps) => (
  <Content className="border-t">
    <div className="exclude-child p-2"> {children}</div>
  </Content>
);
