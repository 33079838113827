import { IconButton, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { type FooterSocialLogosLinkType, footerSocialLogosLinks } from '../links/links-data';

export const SocialIcons = () => {
  return (
    <Stack marginTop={{ xs: 3, sm: 0 }} gap={2}>
      <Typography color="text-inverse" className="font-bold" asChild>
        <h6>
          <FormattedMessage id="COMMON.FOOTER.SOCIAL.FOLLOW" />
        </h6>
      </Typography>
      <Stack direction="row">
        {footerSocialLogosLinks.map((link: FooterSocialLogosLinkType) => (
          <IconButton
            sx={{ padding: 0.5, ':first-of-type': { paddingLeft: 0 } }}
            key={link.href}
            color="primary"
            href={link.href}
            target="_blank"
          >
            {link.icon}
          </IconButton>
        ))}
      </Stack>
    </Stack>
  );
};
