import { Box, Stack, SvgIcon } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import KlarnaLogo from '@aph/images/klarna_logo.svg';
import SwishLogo from '@aph/images/swish_logo.svg';
import { Typography } from '@aph/ui/components/typography/typography';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { footerLegalLinks, footerLegalLogosLinks } from '../links/links-data';
import { ImageLink } from './image-link';

export const PartnersAndLegal = () => {
  const getCurrentYear = () => new Date().getFullYear();

  const { apotekLegalLogo, vetPharmacyLogo, pharmacyLogo } = footerLegalLogosLinks;
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} marginY={2} justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignSelf={{ xs: 'flex-start', sm: 'center' }}>
          <Stack width="100%" paddingRight={1} direction="row">
            <SvgIcon inheritViewBox sx={{ width: 80, height: 33 }}>
              <KlarnaLogo />
            </SvgIcon>
            <div className="bg-elevated flex h-[32px] w-[80px] items-center justify-center rounded-md">
              <SwishLogo />
            </div>
          </Stack>
        </Box>
        <Stack
          direction="row"
          marginTop={{ xs: 2, sm: 0 }}
          spacing={{ xs: 1, sm: 12 }}
          paddingRight={4}
        >
          <Box marginRight={4}>
            <ImageLink
              imgSrc={apotekLegalLogo.imgSrc}
              imgAlt={apotekLegalLogo.imgAlt}
              height={apotekLegalLogo.imgHeight}
              width={apotekLegalLogo.imgWidth}
              href={apotekLegalLogo.href}
            />
          </Box>
          <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
            <ImageLink
              imgSrc={vetPharmacyLogo.imgSrc}
              imgAlt={vetPharmacyLogo.imgAlt}
              height={vetPharmacyLogo.imgHeight}
              width={vetPharmacyLogo.imgWidth}
              href={vetPharmacyLogo.href}
            />
            <ImageLink
              imgSrc={pharmacyLogo.imgSrc}
              imgAlt={pharmacyLogo.imgAlt}
              height={pharmacyLogo.imgHeight}
              width={pharmacyLogo.imgWidth}
              href={pharmacyLogo.href}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box
        width={{ xs: 'calc(100% - 32px)', sm: '100%' }}
        height="1px"
        position="absolute"
        right={{ sm: 0 }}
        left={{ sm: 0 }}
        bgcolor={(theme) => theme.palette['color/border/default']}
      />

      <Box
        paddingY={{ xs: 3, sm: 2 }}
        height="fit-content"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          className="order-2 mt-4 w-full sm:order-1 sm:mt-0 sm:w-fit"
          typography="subHeading"
          color="text-inverse"
          asChild
        >
          <span>
            <FormattedMessage
              id="COMMON.FOOTER.LEGAL.COPYRIGHT_TEXT"
              values={{ currentYear: getCurrentYear() }}
            />
          </span>
        </Typography>
        <Stack
          width={{ xs: '100%', sm: 'fit-content' }}
          order={{ xs: 1, sm: 2 }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2, sm: 5 }}
        >
          {footerLegalLinks.map((link) => (
            <Typography key={link.href} typography="subHeading" color="text-inverse" asChild>
              <FeatureToggledNextLink
                href={link.href}
                style={{ textDecoration: 'none' }}
                forceAnchorLink={link.isEpiLink}
              >
                {link.title}
              </FeatureToggledNextLink>
            </Typography>
          ))}
        </Stack>
      </Box>
    </>
  );
};
