import React from 'react';
import NextLink from 'next/link';
import { Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { notEmpty } from '@aph/utilities/not-empty';
import { appendLeadingSlash } from '@aph/utilities/slug';
import type { ICategoryReference } from '~/articles/generated/ArticlesClient';
import type { QueryResult } from './types';

type CategorySearchResultsProps = QueryResult<ICategoryReference[]>;
export const CategorySearchResults: React.FC<CategorySearchResultsProps> = ({
  isLoading,
  data,
}) => {
  let categories = null;

  if (isLoading) {
    categories = (
      <>
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
      </>
    );
  } else {
    categories = data
      ?.filter((category) => notEmpty(category.slug) && notEmpty(category.name))
      ?.map((category) => (
        <NextLink
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          href={appendLeadingSlash(category.slug!)}
          rel="nofollow"
          key={category.code}
          className="bg-visual text-action-dark mr-0 inline-flex rounded-2xl px-1.5 py-0.5 text-sm no-underline"
        >
          {category.name}
        </NextLink>
      ));

    if ((data?.length ?? 0) < 1) {
      categories = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }
  }

  return <div className="flex flex-wrap gap-0.5">{categories}</div>;
};
